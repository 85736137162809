<template>
    <v-form v-model="valid" v-on:submit.prevent="" ref="contactForm">
        <v-row dense>
            <v-col cols="12" sm="2">
                <v-combobox
                    v-model="contact.salutation"
                    label="Title"
                    :items="data.salutations"
                    :rules="rules.salutation"
                    hint="Optional"
                    outlined
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                        v-model="contact.firstname"
                        label="First Name *"
                        hint="Required"
                        outlined
                        :rules="rules.required"
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                        v-model="contact.lastname"
                        label="Last Name *"
                        hint="Required"
                        outlined
                        :rules="rules.required"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                        v-model="contact.phone1"
                        :label="'Business Phone' + (!contact.phone2 ? ' *' : '')"
                        outlined
                        :rules="(!!contact.phone1 || !contact.phone2) ? rules.phone : []"
                        hint="Geographic(area code) or 13/1300/1800 - no mobiles"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="contact.phone2"
                    :label="'Mobile Phone' + (!contact.phone1 ? ' *' : '')"
                    outlined
                    :rules="(!!contact.phone2 || !contact.phone1) ? rules.mobile : []"
                    hint="SMS alerts (such as suspension warnings) will be sent here"
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="contact.email"
                    label="Email *"
                    outlined
                    :rules="rules.email"
                    :hint="(hints.email == null ? 'This must be an end-user email - no partner emails. Bills and account notifications will be sent to this address.' : hints.email)"
                />
            </v-col>
            <v-col cols="12" v-if="showBcc">
                <v-text-field
                    v-model="contact.bcc"
                    label="BCC Email"
                    outlined
                    :rules="rules.bcc"
                    :error-messages="(!!contact.bcc && contact.bcc===contact.email) ? 'BCC cannot be the same as E-mail' : ''"
                    hint="Optional - A copy of all bills and account notifications will be sent here."
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'ContactDetailsForm',
    mixins: [GlobalHelperMixin],
    data: () => ({
        valid: false,
        contact: {
            salutation: '',
            firstname: '',
            lastname: '',
            phone1: '',
            phone2: '',
            email: ''
        },
        rules: {
            required: [
                v => !!v || 'Required',
            ],
            salutation: [
                v => (!v || v.length <= 5) || 'Title too long',
                v => !/[^A-Za-z]/.test(v) || 'Invalid title'
            ],
            phone: [
                v => !!v || 'At least one phone number is required',
                v => !/^04.*$/.test(v) || 'Mobile number must go in "Mobile Phone" field',
                v => /^(?:(?:0[2-47-8]\d{2}|(?:1300|1800))\d{6}|13\d{4})$/.test(v) || 'Must be a valid phone number'
            ],
            mobile: [
                v => !!v || 'At least one phone number is required',
                v => /^04\d{8}$/.test(v) || 'Must be a valid mobile number'
            ],
            email: [
                v => !!v || 'E-mail is required',
                v => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Must be a valid email address'
            ],
            bcc: [
                v => !v || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Not a valid email address'
            ],
        },
        data: {
            salutations: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms']
        },
    }),
    methods: {
        // updateData(data) {
        //     if (data===this.contact) return;
        //     if (data) this.contact = { ...this.contact, ...data };
        // },

        setup(data){
          /**
           * In order to prevent a loop where the valid toggle is triggered, the contact data is emitted and the contact data is reset in the parent.
           * We check if the new contact data is different and assign if so.
           */
          if (data && !this.equalObjects(data,this.contact)){
              this.contact = data;
          }

          if(data){
            /**
             * we want to validate the form so it shows error messages.
             * Note ideally this should not be needed if the system is correct and valid data is saved.
             */
            // Trigger validation
            this.$nextTick(() => {
              this.$refs.contactForm.validate();
            });
          }
        },

        emitData(){
          if(this.valid){
            this.$emit('input',this.contact);
          }else{
            this.$emit('input',null);
          }
        }
    },
    watch: {
      /**
       * Togged on change of contact. Will emit the data within contact if the form is valid.
       */
        // contact: {
        //     handler(data) {
        //         if (this.contact.phone1==='61') this.contact.phone1 = '0';
        //         if (this.contact.phone2==='61') this.contact.phone2 = '0';
        //         if(this.valid){
        //           data.title = data.salutation;
        //           this.$emit('input',data);
        //         }else{
        //           this.$emit('input',null);
        //         }
        //     },
        //     deep: true
        // },
        // value(data) {
        //     this.updateData(data);
        // },
        // valid() {
        //     this.$emit('input', this.valid ? this.contact : null)
        // }
      valid(data) {
        this.emitData();
      },
      // value(data) {
      //   console.log('value triggered');
      //   if(data) {
      //     this.setup(data);
      //   }
      // },
      contactData(data) {
        this.setup(data);
      },
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        showBcc: {
            type: Boolean,
            default: false
        },
        contactData:{
          type: Object,
          default:null
        },
        hints:{
          type: Object,
          default: function( ){
            return {};
          },
        }
    },
    created() {
        // this.updateData(this.value);
      this.setup(this.contactData);
    }
};
</script>